import calculatorConfig from "@/calculatorConfig"
import ButtonNavigation from "@/components/ButtonNavigation"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import RadioButtons from "@/components/RadioButtons"

const TaxYearsStep: ReactiveComponent = () => {
	return () => (
		<>
			<div class="col-12 claim-label bold">
				<p>In the past 4 years was your income between £12,500 and £50,000 per year?</p>
			</div>
			<div class="col-12 text-center my-2">
				{Object.entries(calculatorConfig.earningsBands).map(([key, band]) => (
					<label key={key} class={[key === App.CustomerClaim.EarningsBand && "selected", "mb-1 py-1 px-4 fancy-radio"]}>
						<input
							type="radio"
							class="required"
							data-validation="Please select your earnings band to continue"
							name="Income"
							value={key}
							v-model={App.CustomerClaim.EarningsBand}
						/>
						<span class="px-3">{band.title}</span>
					</label>
				))}
			</div>

			<div class="col-12 my-2 text-center">
				<p class="small bold my-0">Are you currently married or in a civil partnership?</p>
				<RadioButtons
					name="married"
					value={App.CustomerClaim.Married}
					setValue={(value) => (App.CustomerClaim.Married = value)}
					data-validation="Please tell us whether or not you are currently married or in a civil partnership"
				/>
			</div>

			{App.CustomerClaim.Married && (
				<div class="col-12 my-2 text-center">
					<p class="small bold my-0">Is your spouse employed?</p>
					<RadioButtons
						name="spouseemployed"
						value={App.CustomerClaim.SpouseEmployed}
						setValue={(value) => (App.CustomerClaim.SpouseEmployed = value)}
						data-validation="Please tell us if your spouse is employed"
					/>
				</div>
			)}
			<ButtonNavigation />
		</>
	)
}

export default defineComponent(TaxYearsStep)
