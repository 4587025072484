import ButtonNavigation from "@/components/ButtonNavigation"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import ReviewDetails from "./components/ReviewDetails"

const ReviewStep: ReactiveComponent = () => {
	return () => (
		<>
			<div class="col-12 my-2">
				<h2 class="bold">Tax Claim</h2>
				<h3 class="bold">Final Review</h3>
				<br />
				<p>
					You have provided all of the details you need to submit your claim to HMRC. Please check that the information you have provided is
					correct before clicking the button at the bottom to submit your details.
				</p>
			</div>
			<ReviewDetails />
			<ButtonNavigation />
		</>
	)
}

export default defineComponent(ReviewStep)
