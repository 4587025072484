import ButtonNavigation from "@/components/ButtonNavigation"
import RadioButtons from "@/components/RadioButtons"
import type { ClaimCustomer } from "@/types"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const SelfAssessedChecksStep: ReactiveComponent = () => {
	function updateSACProperty<K extends keyof ClaimCustomer, V extends ClaimCustomer[K] & boolean>(key: K, value: V) {
		App.CustomerClaim[key] = value

		// If any questions on self assessed check step have been answered yes/true set claim to self assessed
		// Don't need qualifying check here - gets set after the claim is saved
		const sacChecks = Object.entries(App.CustomerClaim).filter(
			([key, value]) => key.startsWith("SAC") && (typeof value === "boolean" || value === null)
		)
		if (sacChecks.some(([, value]) => value === true)) {
			App.CustomerClaim.selfAssessed = true
		} else if (sacChecks.every(([, value]) => value === false)) {
			App.CustomerClaim.selfAssessed = false
		} else {
			App.CustomerClaim.selfAssessed = null
		}
	}

	return () => (
		<>
			<div class="col-12 my-2 text-center">
				If you only receive a salary from your job you are usually not required to prepare a self assessment tax return. Please check the list
				below and confirm if any apply to you.
			</div>

			<div class="col-12 text-center my-2">
				<div class="d-table w-100" id="self-assessed-checks-table">
					<div class="d-table-row">
						<RadioButtons
							legend="I am self employed"
							name="sac-self-employed"
							value={App.CustomerClaim.SACIsSelfEmployed}
							setValue={(value) => updateSACProperty("SACIsSelfEmployed", value)}
							data-validation="Please tell us whether or not you are self employed"
							required
						/>
					</div>
					<div class="d-table-row">
						<RadioButtons
							legend="I already have an accountant or tax advisor"
							name="sac-accountant"
							value={App.CustomerClaim.SACHasAccountant}
							setValue={(value) => updateSACProperty("SACHasAccountant", value)}
							data-validation="Please tell us whether or not you already have an accountant or tax advisor"
							required
						/>
					</div>
					<div class="d-table-row">
						<RadioButtons
							legend="I am a company director"
							name="sac-director"
							value={App.CustomerClaim.SACIsDirector}
							setValue={(value) => updateSACProperty("SACIsDirector", value)}
							data-validation="Please tell us whether or not you are a company director"
							required
						/>
					</div>
					<div class="d-table-row">
						<RadioButtons
							legend="I have a property I let out to tenants"
							name="sac-property"
							value={App.CustomerClaim.SACHasProperty}
							setValue={(value) => updateSACProperty("SACHasProperty", value)}
							data-validation="Please tell us whether or not you have a property you let out to tenants"
							required
						/>
					</div>
					<div class="d-table-row">
						<RadioButtons
							legend="I earn more than £100,000 pear year"
							name="sac-earn-100k"
							value={App.CustomerClaim.SACEarns100k}
							setValue={(value) => updateSACProperty("SACEarns100k", value)}
							data-validation="Please tell us whether or not you earn more than £100,000 per year"
							required
						/>
					</div>
					<div class="d-table-row">
						<RadioButtons
							legend="I earn more than £50,000 per year AND either myself or my partner receive child benefit"
							name="sac-earn-50k-plus-bens"
							value={App.CustomerClaim.SACEarns50kPlusBens}
							setValue={(value) => updateSACProperty("SACEarns50kPlusBens", value)}
							data-validation="Please tell us whether or not you earn more than £50,000 per year AND either yourself or your partner receive child benefit"
							required
						/>
					</div>
					<div class="d-table-row">
						<RadioButtons
							legend="I own a business or I am a partner in one"
							name="sac-business"
							value={App.CustomerClaim.SACHasBusiness}
							setValue={(value) => updateSACProperty("SACHasBusiness", value)}
							data-validation="Please tell us whether or not you own a business or are a partner in one"
							required
						/>
					</div>
					<div class="d-table-row">
						<RadioButtons
							legend="I file a tax return with HMRC every year by the 31st January"
							name="sac-tax-return"
							value={App.CustomerClaim.SACFilesTaxReturn}
							setValue={(value) => updateSACProperty("SACFilesTaxReturn", value)}
							data-validation="Please tell us whether or not you file a tax return with HMRC every year by the 31st January"
							required
						/>
					</div>
					<div class="d-table-row">
						<RadioButtons
							legend="I receive more than £10,000 per year income from savings and investments"
							name="sac-investments"
							value={App.CustomerClaim.SAC10kInvestmentsIncome}
							setValue={(value) => updateSACProperty("SAC10kInvestmentsIncome", value)}
							data-validation="Please tell us whether or not you receive more than £10,000 per year income from savings and investments"
							required
						/>
					</div>
					<div class="d-table-row">
						<RadioButtons
							legend="I receive income from another country"
							name="sac-other-country"
							value={App.CustomerClaim.SACIncomeFromOtherCountry}
							setValue={(value) => updateSACProperty("SACIncomeFromOtherCountry", value)}
							data-validation="Please tell us whether or not you receive income from another country"
							required
						/>
					</div>
				</div>
			</div>
			<ButtonNavigation />
		</>
	)
}

export default defineComponent(SelfAssessedChecksStep)
