import { saveClaimToServer } from "@/apis"
import { useLoading } from "@/composition/useLoading"
import type { ClaimStepData } from "@/steps"
import type { ClaimCustomer } from "@/types"
import { setClaimStep, type SetClaimSetOptions } from "../setStep"
import { ClaimedBeforeCheck } from "./claimedBefore"
import { EarningsCheck } from "./earnings"
import { NewTaxPayerCheck } from "./newTaxPayer"
import { NothingToClaimForCheck } from "./nothingToClaimFor"
import { SelfAssessedCheck } from "./selfAssessed"
import { UniformSubscriptionsCheck } from "./uniformSubscriptions"

const { runAction } = useLoading()

export type QualifyingCheckOptions = {
	claim: ClaimCustomer

	requestedNextStep?: ClaimStepData
	nextStep: ClaimStepData
	stepOptions: SetClaimSetOptions
}

export type QualifyingCheckResult = true | ClaimStepData
export type QualifyingCheck = (options: QualifyingCheckOptions) => QualifyingCheckResult | Promise<QualifyingCheckResult>

export const QualifyingChecks: QualifyingCheck[] = [
	EarningsCheck,
	ClaimedBeforeCheck,
	SelfAssessedCheck,
	UniformSubscriptionsCheck,
	NewTaxPayerCheck,
	NothingToClaimForCheck,
]

async function runAllQualifyingChecks(options: QualifyingCheckOptions): Promise<QualifyingCheckResult> {
	for (const check of QualifyingChecks) {
		const result = await check(options)
		if (result !== true) {
			return result
		}
	}
	return true
}

export default async function runQualifyingChecks(options: QualifyingCheckOptions): Promise<boolean> {
	const result = await runAllQualifyingChecks(options)
	if (typeof result === "boolean") {
		return result
	}

	App.CustomerClaim = await runAction(saveClaimToServer(App.CustomerClaim))
	await setClaimStep(result, options.stepOptions)
	return false
}
