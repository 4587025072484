import { insertQualifyingCheck } from "@/apis"
import calculatorConfig from "@/calculatorConfig"
import ClaimSteps, { ClaimType } from "@/steps"
import { ClaimStatus } from "@/types"
import type { QualifyingCheck } from "."

export const EarningsCheck: QualifyingCheck = ({ claim }) => {
	// Not high enough earnings - go to step 99 (once we have their address), don't do anything else
	const defaultEarningsBand =
		claim.EarningsAboveMinimum && claim.Employed ? calculatorConfig.earningsBands["12K-50K-All"] : calculatorConfig.earningsBands["LT-12K-All"]
	const earningsBand = claim.EarningsBand ? calculatorConfig.earningsBands[claim.EarningsBand] : defaultEarningsBand
	const qualifies = earningsBand?.qualifies ?? false
	if (!qualifies && CurrentStep.type === ClaimType.Standard && CurrentStep.step > ClaimSteps.TaxYears.step) {
		claim.ClaimStatus = ClaimStatus.CancelledNoTaxPaid

		void insertQualifyingCheck("Have you paid tax in the last 5 years?", "no", false, claim.ClaimId, "TAX5")

		//if married then let them carry on as they might be due a marriage rebate as the lowest earner
		const isMarriage = claim.QualifiesForMarriageClaim && !claim.MarriageClaimSkipped
		if (!isMarriage) {
			return ClaimSteps.NoTaxPaid
		}
	} else if (CurrentStep.type === ClaimType.Standard && CurrentStep.step > ClaimSteps.TaxYears.step) {
		claim.TaxYearIds = App.currentTaxYears.map((e) => e.Value)
		void insertQualifyingCheck("Have you paid tax in the last 5 years?", "yes", true, claim.ClaimId, "TAX5")
	}

	return true
}
