﻿export interface EarningBand {
	title: string
	qualifies: boolean
}

export interface CalculatorConfig {
	maximumSubscriptions: number
	earningsBands: typeof EarningBands
}

export const EarningBands = {
	"LT-12K-All": {
		title: "Always less than £12,500",
		qualifies: false,
	},
	"12K-50K-All": {
		title: "Usually more than £12,500 and less than £50,000",
		qualifies: true,
	},
	"MT-50K-All": {
		title: "Typically more than £50,000 but always less than £100,000",
		qualifies: true,
	},
	"MT-100K-Some": {
		title: "Sometimes more than £100,000",
		qualifies: true,
	},
	"MT-100K-All": {
		title: "Always more than £100,000",
		qualifies: false,
	},
} satisfies Record<string, EarningBand>

//WARNING: the earningsBands are mirrored in the server-side code UpdateClaimService.cs
const calculatorConfig: CalculatorConfig = {
	maximumSubscriptions: 10,
	earningsBands: EarningBands,
}

export default calculatorConfig
