/* eslint-disable jsx-a11y/label-has-associated-control */
import RadioButtons from "@/components/RadioButtons"
import type { EmploymentRecord } from "@/types"
import { toReactive } from "@vueuse/core"
import { toRef } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	record: EmploymentRecord
}

const EmploymentUniform: ReactiveComponent<Props> = (props) => {
	const record = toReactive(toRef(props, "record"))

	return () => (
		<section>
			<h5>Uniform</h5>

			<RadioButtons
				name={`wore-uniform-${record.EmploymentId}`}
				legend="Did you wear a uniform while at this job?"
				value={record.Uniformed}
				setValue={(value) => (record.Uniformed = value)}
				required
			/>
		</section>
	)
}

export default defineComponent(EmploymentUniform, {
	record: requiredProp(Object),
})
