﻿/* eslint-disable vue/one-component-per-file */

import { ModalsWrapper, showModalMessage } from "@/modals"
import { createApp } from "vue"
import { mergePromises } from "vue-utils"
import {
	cancelClaim,
	getCompanyPAYEData,
	getDetailedTaxYears,
	getIndustrySectors,
	getIndustrySectorsFiltered,
	getOccupationsAll,
	getTaxYears,
} from "./apis"
import { lookupVueInitialize } from "./apps/ClaimLookup"
import { responseFormInit } from "./apps/ClaimResponse"
import { postalUnsubscribeFormInit } from "./apps/PostalUnsubscribe"
import { refundReceivedFormInit } from "./apps/RefundReceivedForm"
import { createTaxClaimApp } from "./apps/TaxClaim"
import { updateAddressFormInit } from "./apps/UpdateAddress"
import "./components"
import { useLoading } from "./composition/useLoading"
import { pandemicYearIds } from "./constants"
import "./modals/testimonialContentModal"
import "./modals/testimonialIframeModal"
import "./styles"
import type { Nullable } from "./types"
import { focusClaimForm, scrollTo } from "./utils/scrolling"

document.getElementById("temporary-stylesheet")?.remove()

let isFocus = false

function numberWithCommas(x: number | string) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

function incrementTotalClaims() {
	const els = $(".totalrefunds.increment")
	if (els.length) {
		els.each(function () {
			const el = $(this)
			const t = el.text()
			const n = parseFloat(t.replace("£", "").replace(/,/g, ""))
			if (n < claimsTotalMax) {
				const newTotal = (n + avgClaim).toFixed(0).toLocaleString()
				el.text(`£${numberWithCommas(newTotal)}`)
			}
		})
	}
}

function incrementClaimCount() {
	const els = $(".refundcount.increment")
	if (els.length) {
		els.each(function () {
			const el = $(this)
			const t = el.text()
			const n = parseFloat(t.replace(/,/g, ""))
			if (n < noOfClaimsMax) {
				const newTotal = (n + 1).toFixed(0).toLocaleString()
				el.text(numberWithCommas(newTotal))
			}
		})
	}
}

function menuResizerInit() {
	window.addEventListener("scroll", () => {
		const distanceY = window.screenY || document.documentElement.scrollTop
		//var shrinkOn = 10;
		const shrinkOn = 10
		if (distanceY > shrinkOn) {
			$("header").addClass("slim")
		} else {
			$("header").removeClass("slim")
		}
	})
}

/*MAIN DOC EVENT*/
// eslint-disable-next-line @typescript-eslint/no-misused-promises
$(async () => {
	menuResizerInit()
	responseFormInit()
	postalUnsubscribeFormInit()
	lookupVueInitialize()
	refundReceivedFormInit()
	updateAddressFormInit()

	if (document.getElementById("ModalsWrapper")) {
		createApp(ModalsWrapper).mount("#ModalsWrapper")
	}

	await createTaxClaimApp()

	void loadInitialData()

	setInterval(incrementTotalClaims, 15000)
	setInterval(incrementClaimCount, 15000)
})

async function loadInitialData() {
	const { runAction } = useLoading()
	const brandIdToUse = brandTypeOverrideId > 0 ? brandTypeOverrideId : brandTypeId
	const result = await runAction(
		mergePromises({
			companyData: getCompanyPAYEData(),
			industrySectors: getIndustrySectors(brandIdToUse),
			industrySectorsFiltered: getIndustrySectorsFiltered(brandIdToUse),
			taxYears: getTaxYears(),
			marriageTaxYears: getDetailedTaxYears(),
			allOccupations: getOccupationsAll(),
		})
	)

	App.industrySectors = result.industrySectors
	App.industrySectorsFiltered = result.industrySectorsFiltered
	App.currentTaxYears = result.taxYears
	App.MarriageTaxYears = result.marriageTaxYears
	App.AllOccupations = result.allOccupations
	App.companyData = result.companyData

	for (const ty of result.taxYears) {
		// Add tax years to claim tax year statuses (if not already present)
		if (!App.CustomerClaim.TaxYearStatuses.some((status) => status.Key === ty.Value)) {
			App.CustomerClaim.TaxYearStatuses.push({ Key: ty.Value, Value: null })
		}
		if (!App.CustomerClaim.WorkFromHomeYears.some((wfh) => wfh.Id === ty.Value)) {
			let w = 0
			if (pandemicYearIds.indexOf(ty.Value) > -1) {
				w = 52
			}
			App.CustomerClaim.WorkFromHomeYears.push({ Id: ty.Value, Value: null, Weeks: w })
		}
	}
}

/*MISC EVENTS*/

//remove focus blur effect and reset site
$("body").on("click", (e) => {
	if (e.target.nodeName.toLowerCase() !== "input" && e.target.nodeName.toLowerCase() !== "select")
		if ($("body").hasClass("blur") && isFocus) {
			if (!e.target.classList.contains("focus") && e.target.parentElement && !e.target.parentElement.classList.contains("focus")) {
				$("body").removeClass("blur")
				$(".focus").removeClass("focus")
				isFocus = false
			}
		}
})
$(window).on("scroll", () => {
	if ($("body").hasClass("blur") && isFocus) {
		$("body").removeClass("blur")
		$(".focus").removeClass("focus")
		isFocus = false
	}
})

$(document).on("click", "#QuickQuote", (e) => {
	focusClaimForm(false)
	e.stopImmediatePropagation()
})

$(document).on("focus", "input.fragment", function () {
	const input = this as HTMLInputElement
	input.select()
})

// eslint-disable-next-line @typescript-eslint/no-misused-promises
$(document).on("click", "button.cancel-claim", async (e) => {
	e.preventDefault()
	const t = $(e.target)
	const u = String(t.data("userid"))
	const c = Number.parseInt(String(t.data("claimid")))

	try {
		await useLoading().runAction(cancelClaim(u, c))
	} catch {
		return
	}

	t.attr("disabled", "disabled")
	t.parent().parent().find("p > button.keep-claim").attr("disabled", "disabled")
	void showModalMessage(
		"Claim cancelled",
		"We have cancelled your claim record and will no longer send your rebate claim to HMRC for processing."
	)
})

// eslint-disable-next-line @typescript-eslint/no-misused-promises
$(document).on("click", "button.keep-claim", async (e) => {
	e.preventDefault()

	await showModalMessage("Claim saved", "Thank you - we will continue to process your claim on your behalf.")
	window.location.replace("/")
})

//select optin
$(document).on("click", '#Claim input[type="radio"][name="optin"]', () => {
	$('#Claim div.step2 button[role="button"]').removeAttr("disabled")
})

$(document).on("click", "a.anchorlink", function (e) {
	e.preventDefault()
	const anchor = $(this).data("anchorid") as Nullable<string>
	const href = $(this).attr("href") as string
	if (anchor !== null) {
		const t = $(`#${anchor}`)
		if (t.length) {
			scrollTo(anchor)
		} else {
			window.location.replace(href)
		}
	} else {
		window.location.replace(href)
	}
})

/*QUICK-QUOTE-Specific stuff goes here*/
