import { defineGlobals } from "vue-utils"
import { ClaimType, type ClaimStepData } from "./types"

export * from "./types"

const step = (step: number, name: string, type: ClaimType = ClaimType.Standard, extra: Partial<ClaimStepData> = {}): ClaimStepData => ({
	step,
	name,
	type,
	isIssue: false,
	...extra,
})

const issueStep = (step: number, name: string, type: ClaimType = ClaimType.Standard, extra: Partial<ClaimStepData> = {}): ClaimStepData => ({
	step,
	name,
	type,
	isIssue: true,
	getPreviousStep: () => null,
	getNextStep: () => null,
	shouldSkipStep: () => true,
	...extra,
})

const Postcode = step(1, "postcode")
const Name = step(2, "name")
const TaxYears = step(3, "tax-years")
const Qualifying = step(4, "qualifying")
const Address = step(5, "address")
const SelfAssessedChecks = step(6, "self-assessed-checks", ClaimType.Standard, {
	shouldSkipStep: (claim) => claim.selfAssessed === true || claim.selfAssessed === false,
})
const Estimate = step(7, "estimate")
const WorkFromHome = step(8, "work-from-home")
const Employer = step(9, "employer")
const EmploymentHistory = step(9, "employment-history")
const Subscriptions = step(10, "subscriptions", ClaimType.Standard, {
	shouldSkipStep: (claim) => claim.Subscriptions.length === 0,
})
const SubscriptionDetails = step(11, "subscription-details", ClaimType.Standard, {
	shouldSkipStep: (claim) => claim.Subscriptions.filter((sub) => sub.Enabled).length === 0,
})
const NationalInsurance = step(12, "NI")
const ReviewClaim = step(13, "review")
const Signature = step(14, "signature", ClaimType.Standard, {})
const ClaimComplete = step(15, "complete")

const BasicInformation = step(3, "basic-information", ClaimType.MoD, {
	getPreviousStep: () => EmploymentHistory,
})
const AboutYou = step(4, "about-you", ClaimType.MoD)
const DetailsPassed = step(5, "details-passed", ClaimType.MoD)

const NoTaxPaid = issueStep(99, "no-tax", ClaimType.Standard, {
	getPreviousStep: () => TaxYears,
})
const AlreadyClaimed = issueStep(100, "already-claimed")
const RequireSelfAssessment = issueStep(101, "require-sa", ClaimType.Standard, {
	getPreviousStep: () => Address,
})
const NoEmploymentHistory = issueStep(103, "no-employment-history")
const LackOfExpenses = issueStep(104, "lack-expenses")
const UniformClaimed = issueStep(106, "uniform-claimed", ClaimType.Standard, {
	getPreviousStep: () => Qualifying,
})
const EmployerDetailsNeeded = issueStep(107, "employer-details-needed", ClaimType.Standard, {
	getPreviousStep: () => EmploymentHistory,
	getNextStep: () => ClaimComplete,
})
const WaitUntilNextTaxYear = issueStep(108, "wait-until-next-tax-year")
const DetailsRejected = issueStep(109, "cancelled-details-rejected")

export const ClaimSteps = {
	Postcode,
	Name,
	TaxYears,
	Qualifying,
	Address,
	SelfAssessedChecks,
	Estimate,
	WorkFromHome,
	Employer,
	EmploymentHistory,
	Subscriptions,
	SubscriptionDetails,
	NationalInsurance,
	ReviewClaim,
	Signature,
	ClaimComplete,

	BasicInformation,
	AboutYou,
	DetailsPassed,

	NoTaxPaid,
	AlreadyClaimed,
	RequireSelfAssessment,
	NoEmploymentHistory,
	LackOfExpenses,
	UniformClaimed,
	EmployerDetailsNeeded,
	WaitUntilNextTaxYear,
	DetailsRejected,

	InitialStep: Postcode,
	FinalStep: ClaimComplete,
	FinalMoDStep: DetailsPassed,
} as const

defineGlobals({
	ClaimSteps,
})

export default ClaimSteps
